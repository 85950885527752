<template>
  <div>
    <b-card no-title>
      <b-card-title>
        {{ $t('card.title.add.client') }}
      </b-card-title>
      <b-card-text>
        {{ $t('card.description.add.client') }}
      </b-card-text>
    </b-card>
    <b-overlay :show="loading">
    <b-card-code>
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="4">
              <b-form-group
                  :label="$t('form.primaryContact')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.primaryContact').toLowerCase()"
                    rules="required"
                    vid="user"
                >
                  <v-select
                      v-model="formData.user"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :clearable="false"
                      :options="userOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  :label="$t('form.taxNumber')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.taxNumber').toLowerCase()"
                    :rules="{required, taxNumber:formData.country.value.toUpperCase()}"
                    vid="taxNumber"
                    ref="taxNumberIsValid"
                >
                  <b-input-group>
                    <b-input-group-prepend v-if="formData.country.value.toUpperCase() !== 'HU'">
                      <b-input-group-text>
                        {{ formData.country.value.toUpperCase() }}
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                        v-model="formData.taxNumber"
                        @input="queryTaxNumber"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('form.taxNumber').toLowerCase()"
                        :formatter="taxNumberFormatter"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  :label="$t('form.companyName')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.companyName').toLowerCase()"
                    rules="required"
                    vid="companyName"
                >
                  <b-form-input
                      v-model="formData.companyName"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('form.companyName').toLowerCase()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  :label="$t('form.country')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.country').toLowerCase()"
                    rules=""
                    vid="country"
                >
                  <v-select
                      v-model="formData.country"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :clearable="false"
                      :options="countryOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  :label="$t('form.zip')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.zip').toLowerCase()"
                    rules=""
                    vid="zip"
                >
                  <b-form-input
                      v-model="formData.zip"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('form.zip').toLowerCase()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  :label="$t('form.city')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.city').toLowerCase()"
                    rules=""
                    vid="city"
                >
                  <b-form-input
                      v-model="formData.city"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('form.city').toLowerCase()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                  :label="$t('form.street')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.street').toLowerCase()"
                    rules=""
                    vid="street"
                >
                  <b-form-input
                      v-model="formData.street"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('form.street').toLowerCase()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                  :label="$t('form.houseNumber')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.houseNumber').toLowerCase()"
                    rules=""
                    vid="houseNumber"
                >
                  <b-form-input
                      v-model="formData.houseNumber"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('form.houseNumber').toLowerCase()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                  :label="$t('form.houseNumberInfo')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.houseNumberInfo').toLowerCase()"
                    rules=""
                    vid="houseNumberInfo"
                >
                  <b-form-input
                      v-model="formData.houseNumberInfo"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('form.houseNumberInfo').toLowerCase()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                  :label="$t('form.status')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.status').toLowerCase()"
                    rules="required"
                    vid="status"
                >
                  <v-select
                      v-model="formData.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :clearable="false"
                      :options="statusOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  :label="$t('form.legalPerson')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.legalPerson').toLowerCase()"
                    rules="required"
                    vid="legalPerson"
                >
                  <v-select
                      v-model="formData.legalPerson"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :clearable="true"
                      :options="legalPersonOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  :label="$t('form.billingSystem')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.billingSystem').toLowerCase()"
                    rules=""
                    vid="billingSystem"
                >
                  <v-select
                      v-model="formData.billingSystem"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :clearable="true"
                      :options="billingSystemOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit button -->
            <b-col class="mt-1">
              <b-button
                  variant="primary"
                  type="submit"
                  class="float-right"
                  @click.prevent="submitForm"
              >
                {{ $t('label.button.save') }}
              </b-button>
              <cancel-and-g-o-t-o-previous-page class="mr-2"/>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BCard, BCardTitle, BCardText, BForm, BRow, BCol, BButton, BFormCheckbox, BOverlay,
    BInputGroup, BInputGroupPrepend, BInputGroupText
} from 'bootstrap-vue'
import {
  required, taxNumber
} from '@validations'
import vSelect from 'vue-select'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import { formatTaxNumber } from "@core/utils/filter"
import i18nHelper from "@/libs/helper/i18nHelper";

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BCardText,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BOverlay,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupText,
    vSelect,
    cancelAndGOTOPreviousPage,
  },
  data() {
    return {
      role: 'client',
      formData: {
        user: {
          value: '',
          title: ''
        },
        legalPerson: {
          value: '',
          title: ''
        },
        companyName: '',
        taxNumber: '',
        country: {
          value: '',
          title: ''
        },
        zip: '',
        city: '',
        street: '',
        houseNumber: '',
        houseNumberInfo: '',
        status: {
          value: '',
          title: ''
        },
        billingSystem: {
          value: '',
          title: ''
        },
      },
      legalPersonOptions: [],
      userOptions: [],
      countryOptions: [],
      statusOptions: [],
      billingSystemOptions: [],
      loading: false,
      required,
      taxNumber,
      formatTaxNumber
    }
  },
  created() {
    this.loading = true

    this.$store.dispatch('fetchUsersForSelect').then(response => {
      this.userOptions = response

      if (this.userOptions.length) {
        this.formData.user = this.userOptions[0]
      }
    })

    this.$store.dispatch('fetchLegalPersonsForSelect').then(response => {
      this.legalPersonOptions = response

      if (this.legalPersonOptions.length) {
        this.formData.legalPerson = this.legalPersonOptions[0]
      }
    })

    this.$store.dispatch('fetchCountriesForSelect').then(response => {
      this.countryOptions = response

      if (this.countryOptions.length) {
        this.formData.country = {
          value: 'HU',
          title: i18nHelper.localizeCountry('HU')
        }
      }
    })

    this.$store.dispatch('fetchClientStatuses').then(response => {
      if (response.data.values) {
        for (const key in response.data.values) {
          this.statusOptions.push({
            value: response.data.values[key].value,
            title: this.$t('clients.statuses.'+response.data.values[key].title),
          })
        }

        if (this.statusOptions.length) {
          // eslint-disable-next-line prefer-destructuring
          this.formData.status = this.statusOptions[0]
        }
      }
    })

    this.$store.dispatch('fetchClientBillingSystems').then(response => {
      if (response.data.values) {
        for (const key in response.data.values) {
          this.billingSystemOptions.push({
            value: response.data.values[key].value,
            title: this.$t('clients.billingSystems.'+response.data.values[key].title),
          })
        }

        if (this.billingSystemOptions.length) {
          // eslint-disable-next-line prefer-destructuring
          this.formData.billingSystem = this.billingSystemOptions[0]
        }
      }
    }).finally(() => {
      this.loading = false
    })
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loading = true
          // clone formData
          let fd = JSON.parse(JSON.stringify(this.formData))
          fd.user = this.formData.user.value
          fd.country = this.formData.country.value
          fd.status = this.formData.status.value
          fd.legalPerson = this.formData.legalPerson.value
          if(this.formData.billingSystem) {
            fd.billingSystem = this.formData.billingSystem.value
          }

          this.$store.dispatch('storeClient', fd).then(response => {
            this.$helpers.showSuccessToast()

            this.$acl.canListPermission(this.role)
                ? this.$router.push({name: 'modify-client', params: {id: response.data.entity.id}})
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    queryTaxNumber(){
      this.$refs.taxNumberIsValid.validate().then(success => {
        if (success.valid) {
          this.loading = true
          let actionName
          let isoCode
          if (this.formData.country.value === 'HU') {
            actionName = 'getTaxNumberHu'
            isoCode = ''
          } else {
            actionName = 'getTaxNumberEu';
            isoCode = this.formData.country.value.toUpperCase()
          }

          this.$store.dispatch(actionName, {'taxNumber': isoCode+this.formData.taxNumber}).then(response => {
            let taxpayer = response.data.taxpayer

            console.log(taxpayer)

            this.formData.companyName = taxpayer.name
            this.formData.taxNumber = taxpayer.taxNumber
            this.formData.zip = taxpayer.zip
            this.formData.city = taxpayer.city
            this.formData.street = taxpayer.street
            this.formData.houseNumber = taxpayer.houseNumber
            this.formData.houseNumberInfo = taxpayer.houseNumberInfo

          }).catch(error => {
            if (error.response.status === 422) {
              this.clearAddressData()
              if (Object.keys(error.response.data).includes('errors')) {
                console.log(error.response.data.errors)
                this.$refs.simpleRules.setErrors(error.response.data.errors)
              } else {
                // TODO: set errors!
                this.$helpers.showErrorToast(error.response.data.message)
              }
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          }).finally(() => {
            this.loading = false;
          })
        }
      });
    },
    clearAddressData(){
      this.formData.companyName = ''
      this.formData.zip = ''
      this.formData.city = ''
      this.formData.street = ''
      this.formData.houseNumber = ''
      this.formData.houseNumberInfo = ''
    },
    taxNumberFormatter(value){
      const countryCode = this.formData.country.value.toUpperCase()

      return formatTaxNumber(value, countryCode)
    }
  },
}
</script>
