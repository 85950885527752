var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"no-title":""}},[_c('b-card-title',[_vm._v(" "+_vm._s(_vm.$t('card.title.add.client'))+" ")]),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.$t('card.description.add.client'))+" ")])],1),_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.primaryContact')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.primaryContact').toLowerCase(),"rules":"required","vid":"user"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","clearable":false,"options":_vm.userOptions},model:{value:(_vm.formData.user),callback:function ($$v) {_vm.$set(_vm.formData, "user", $$v)},expression:"formData.user"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.taxNumber')}},[_c('validation-provider',{ref:"taxNumberIsValid",attrs:{"name":_vm.$t('form.taxNumber').toLowerCase(),"rules":{required: _vm.required, taxNumber:_vm.formData.country.value.toUpperCase()},"vid":"taxNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[(_vm.formData.country.value.toUpperCase() !== 'HU')?_c('b-input-group-prepend',[_c('b-input-group-text',[_vm._v(" "+_vm._s(_vm.formData.country.value.toUpperCase())+" ")])],1):_vm._e(),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.taxNumber').toLowerCase(),"formatter":_vm.taxNumberFormatter},on:{"input":_vm.queryTaxNumber},model:{value:(_vm.formData.taxNumber),callback:function ($$v) {_vm.$set(_vm.formData, "taxNumber", $$v)},expression:"formData.taxNumber"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.companyName')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.companyName').toLowerCase(),"rules":"required","vid":"companyName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.companyName').toLowerCase()},model:{value:(_vm.formData.companyName),callback:function ($$v) {_vm.$set(_vm.formData, "companyName", $$v)},expression:"formData.companyName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.country')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.country').toLowerCase(),"rules":"","vid":"country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","clearable":false,"options":_vm.countryOptions},model:{value:(_vm.formData.country),callback:function ($$v) {_vm.$set(_vm.formData, "country", $$v)},expression:"formData.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.zip')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.zip').toLowerCase(),"rules":"","vid":"zip"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.zip').toLowerCase()},model:{value:(_vm.formData.zip),callback:function ($$v) {_vm.$set(_vm.formData, "zip", $$v)},expression:"formData.zip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.city')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.city').toLowerCase(),"rules":"","vid":"city"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.city').toLowerCase()},model:{value:(_vm.formData.city),callback:function ($$v) {_vm.$set(_vm.formData, "city", $$v)},expression:"formData.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.street')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.street').toLowerCase(),"rules":"","vid":"street"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.street').toLowerCase()},model:{value:(_vm.formData.street),callback:function ($$v) {_vm.$set(_vm.formData, "street", $$v)},expression:"formData.street"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.houseNumber')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.houseNumber').toLowerCase(),"rules":"","vid":"houseNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.houseNumber').toLowerCase()},model:{value:(_vm.formData.houseNumber),callback:function ($$v) {_vm.$set(_vm.formData, "houseNumber", $$v)},expression:"formData.houseNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.houseNumberInfo')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.houseNumberInfo').toLowerCase(),"rules":"","vid":"houseNumberInfo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.houseNumberInfo').toLowerCase()},model:{value:(_vm.formData.houseNumberInfo),callback:function ($$v) {_vm.$set(_vm.formData, "houseNumberInfo", $$v)},expression:"formData.houseNumberInfo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.status')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.status').toLowerCase(),"rules":"required","vid":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","clearable":false,"options":_vm.statusOptions},model:{value:(_vm.formData.status),callback:function ($$v) {_vm.$set(_vm.formData, "status", $$v)},expression:"formData.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.legalPerson')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.legalPerson').toLowerCase(),"rules":"required","vid":"legalPerson"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","clearable":true,"options":_vm.legalPersonOptions},model:{value:(_vm.formData.legalPerson),callback:function ($$v) {_vm.$set(_vm.formData, "legalPerson", $$v)},expression:"formData.legalPerson"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.billingSystem')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.billingSystem').toLowerCase(),"rules":"","vid":"billingSystem"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","clearable":true,"options":_vm.billingSystemOptions},model:{value:(_vm.formData.billingSystem),callback:function ($$v) {_vm.$set(_vm.formData, "billingSystem", $$v)},expression:"formData.billingSystem"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-1"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('label.button.save'))+" ")]),_c('cancel-and-g-o-t-o-previous-page',{staticClass:"mr-2"})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }